/* Colors */
:root {
    --back-color: #F4F5F4;
    --gold-color: #d5aa6d;
    --white-nav-color: #d7d7d7;
    --blue-highligth: #2ea3f2;
    --desc-text: #b8b8b8;
}
/* Colors */
/* General */
body{
    background-color: var(--back-color);
}
.centrar{
    display: flex;
    justify-content: center;
}
.centrar-v{
    display: flex;
    align-items: center;
}
.centrar-vh{
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-container{
    min-height: calc(100vh);
    padding-top: 90px;
}
@media (max-width: 1900px) {
    .main-container{
        padding-top: 65px;
    }
}
@media (max-width: 767px) {
    .main-container{
        padding-top: 60px;
    }
}
/* General */
/* Navbar */
.navbar.navbar-dark{
    background-color: black;
}
.navbar-brand img{
    width: 100px;
    height: auto;
}
@media (max-width: 1900px) {
    .navbar-brand img{
        width: 60px;
    }
}
@media (max-width: 767px) {
    .navbar-brand img{
        width: 50px;
    }
}
.navbar-ul-container{
    display: flex;
    justify-content: right;
}
@media (max-width: 991px) {
    .navbar-ul-container{
        justify-content: left;
    }
}
.nav-link{
    color: var(--white-nav-color) !important;
}
.nav-link.active{
    color: var(--gold-color) !important;
}
/* Navbar */
/* Footer */
.footer-top{
    background-color: #222222;
    color: white;
    font-size: 14px;
}
.footer-bottom{
    background-color: #171717;
    color: var(--white-nav-color);
    font-size: 14px;
}
.footer-logo{
    width: auto;
    height: 50%;
}
.footer-header{
    color: var(--gold-color);
    font-family: "Playfair Display";
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 3px;
}
.footer-content{
    text-align: justify;
}
.footer-ul li a{
    color: white;
    text-decoration: none;
}
.footer-ul li::marker{
    color: var(--gold-color)
  }
/* Footer */
/* Aviso de privacidad */
.aviso-banner{
    background-image: url('../public/img/aviso-banner.jpg');
    background-size: cover;
    width: 100%;
    height: 400px;
}
@media (max-width: 1024px) {
    .aviso-banner{
        height: 300px;
    }
}
@media (max-width: 768px) {
    .aviso-banner{
        height: 250px;
    }
}
@media (max-width: 767px) {
    .aviso-banner{
        height: 200px;
    }
}
.aviso-banner-text{
    color: white;
    font-family: "Playfair Display";
    font-size: 40px;
    font-weight: 500;
}
@media (max-width: 768px) {
    .aviso-banner-text{
        font-size: 30px;
    }
}
.aviso-header{
    color: var(--gold-color);
    font-family: "Playfair Display";
    font-size: 20px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 3px;
}
.aviso-text{
    font-size: 14px;
    text-align: justify;
}
.aviso-highligth{
    color: var(--gold-color);
    text-decoration: none;
}
.aviso-highligth:hover{
    color: var(--gold-color);
}
.aviso-highligth:active{
    color: var(--gold-color);
}
/* Aviso de privacidad */
/* Contacto */
.contacto-banner{
    background-image: url('../public/img/contacto-banner.jpg');
    background-size: cover;
    width: 100%;
    height: 400px;
}
@media (max-width: 1024px) {
    .contacto-banner{
        height: 300px;
    }
}
@media (max-width: 768px) {
    .contacto-banner{
        height: 250px;
    }
}
@media (max-width: 767px) {
    .contacto-banner{
        height: 200px;
    }
}
.contacto-banner-text-header{
    color: white;
    font-family: "Playfair Display";
    font-size: 40px;
    font-weight: 500;
}
@media (max-width: 768px) {
    .contacto-banner-text-header{
        font-size: 30px;
    }
}
.contacto-banner-text-subheader{
    color: white;
    font-size: 14px;
}
@media (max-width: 768px) {
    .contacto-banner-text-subheader{
        font-size: 12px;
    }
}
.contacto-columns{
    background-color: black;
    padding-top: 80px;
    padding-bottom: 80px;
}
@media (max-width: 767px) {
    .contacto-columns{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.contacto-columns-icon{
    background-size: cover;
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
}
@media (max-width: 767px) {
    .contacto-columns-icon{
        margin-bottom: 0px;
    }   
}
.contacto-columns-marcador-icon{
    background-image: url('../public/img/marcador_mapa_icon.png');
}
.contacto-columns-telefono-icon{
    background-image: url('../public/img/telefono_icon.png');
}
.contacto-columns-correo-icon{
    background-image: url('../public/img/correo_icon.png');
}
.contacto-columns-text{
    color: var(--white-nav-color);
    text-align: center;
    font-size: 14px;
}
@media (max-width: 1023px) {
    .contacto-columns-text{
        font-size: 12px;
    }    
}
@media (max-width: 767px) {
    .contacto-columns-text{
        margin-bottom: 10px;
    }    
}
.contacto-columns-text-link{
    color: var(--gold-color);
    text-decoration: none;
}
.contacto-columns-text-link:hover{
    color: var(--gold-color);
}
.contacto-columns-text-link:active{
    color: var(--gold-color);
}
.contacto-mapa{
    border: 0;
    width: 100%;
    height: 450px;
}
/* Contacto */
/* ContactoForm */
.contacto-form-container{
    padding-top: 80px;
    padding-bottom: 80px;
}
@media (max-width: 767px) {
    .contacto-form-container{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.contacto-form-header{
    font-family: "Playfair Display";
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 3px;
}
@media (max-width: 768px) {
    .contacto-form-header{
        font-size: 20px;
    }
}
.contacto-form-subheader{
    font-size: 14px;
    margin-bottom: 25px;
}
@media (max-width: 768px) {
    .contacto-form-subheader{
        font-size: 12px;
    }
}
.form-control-contacto{
    border: none !important;
    border-radius: 2px !important;
    padding: 0.75rem 0.75rem !important;
}
.form-control-contacto-ligth{
    background-color: #EEEEEE;
    color: black !important;
}
.form-control-contacto-ligth:focus{
    background-color: #EEEEEE;
}
.form-control-contacto-dark{
    background-color: #242424;
    color: white !important;
}
.form-control-contacto-dark:focus{
    background-color: #242424;
}
.form-control-contacto-btn{
    background-color: black !important;
    color: white !important;
    border-radius: 2px;
}
/* ContactoForm */
/* Home carousel */
.carousel-item{
    height: 45vh;
}
.carousel-img{
    height: -webkit-fill-available;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.carousel-header{
    font-family: "Playfair Display";
    font-size: 28px;
    font-weight: 600;
    color: var(--gold-color);
    text-shadow: 3px 1px 2px rgba(0,0,0,0.6);
}
.carousel-subheader{
    color: white;
    text-shadow: 3px 1px 2px rgba(0,0,0,0.6);
}
/* Home carousel */
/* Clientes banner */
.clientes-banner-container{
    background-color: black;
    padding-top: 80px;
    padding-bottom: 80px;
}
@media (max-width: 767px) {
    .clientes-banner-container{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.clientes-banner-header{
    font-family: "Playfair Display";
    font-size: 28px;
    font-weight: 500;
    color: var(--gold-color);
    text-align: center;
}
.clientes-banner-subheader{
    color: white;
    font-size: 14px;
    text-align: center;
}
.clientes-divider-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 20px;
    padding-bottom: 20px;
}
.clientes-divider{
    background-color: var(--gold-color);
    height: 1px;
    width: 100%;
}
.clientes-banner-cliente{
    width: 100%;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.clientes-banner-cliente-1{
    background-image: url('../public/img/clientes/banco-banregio.png');
}
.clientes-banner-cliente-2{
    background-image: url('../public/img/clientes/banco-banorte.png');
}
.clientes-banner-cliente-3{
    background-image: url('../public/img/clientes/banco-banjio.png');
}
.clientes-banner-cliente-4{
    background-image: url('../public/img/clientes/banco-banorte.png');
}
/* Clientes banner */
/* Servicios banner */
.servicios-banner-container{
    padding-top: 80px;
    padding-bottom: 80px;
}
@media (max-width: 767px) {
    .servicios-banner-container{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.servicios-banner-header{
    font-family: "Playfair Display";
    font-size: 28px;
    font-weight: 500;
    color: var(--gold-color);
    text-align: center;
}
.servicios-banner-subheader{
    font-size: 14px;
    text-align: center;
}
.servicios-divider-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 20px;
    padding-bottom: 20px;
}
.servicios-divider{
    background-color: var(--gold-color);
    height: 1px;
    width: 100%;
}
.servicios-banner-servicio{
    width: 100%;
    height: 220px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
@media (max-width: 1500px) {
    .servicios-banner-servicio{
        height: 160px;
    }
}
.servicios-banner-servicio-1{
    background-image: url('../public/img/servicios/operaciones-de-garantia-1.png');
}
.servicios-banner-servicio-2{
    background-image: url('../public/img/servicios/sociedades-y-asocioanes.png');
}
.servicios-banner-servicio-3{
    background-image: url('../public/img/servicios/inmuebles.png');
}
.servicios-banner-servicio-4{
    background-image: url('../public/img/servicios/sucesorios.png');
}
.servicios-banner-servicio-description{
    font-family: "Playfair Display";
    font-size: 18px;
    font-weight: 500;
    color: var(--gold-color);
    text-align: center;
}
@media (max-width: 1500px) {
    .servicios-banner-servicio-description{
        font-size: 14px;
    }
}
.servicios-banner-btn{
    background-color: black;
    color: white;
    margin-top: 25px;
    font-size: 18px;
}
@media (max-width: 768px) {
    .servicios-banner-btn{
        font-size: 14px;
    }
}
.servicios-banner-btn:hover{
    background-color: black;
    color: white;
}
/* Servicios banner */
/* Background on container */
.background-on-container-back{
    background-image: url('../public/img/bg-contact.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 80px 30% 80px 30%;
}
@media (max-width: 1900px) {
    .background-on-container-back{
        padding: 60px 25% 60px 25%;
    }
}
@media (max-width: 1024px) {
    .background-on-container-back{
        padding: 50px 20% 50px 20%;
    }
}
@media (max-width: 768px) {
    .background-on-container-back{
        padding: 40px 15% 40px 15%;
    }
}
@media (max-width: 767px) {
    .background-on-container-back{
        padding: 40px 10% 40px 10%;
    }
}
.background-on-container-container{
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
}
@media (max-width: 1024px) {
    .background-on-container-container{
        padding-left: 30px;
        padding-right: 30px;
    }
}
/* Background on container */
/* Servicios */
.servicios-banner{
    background-image: url('../public/img/servicios-banner.jpg');
    background-size: cover;
    width: 100%;
    height: 400px;
}
@media (max-width: 1024px) {
    .servicios-banner{
        height: 300px;
    }
}
@media (max-width: 768px) {
    .servicios-banner{
        height: 250px;
    }
}
@media (max-width: 767px) {
    .servicios-banner{
        height: 200px;
    }
}
.servicios-banner-text-header{
    color: white;
    font-family: "Playfair Display";
    font-size: 40px;
    font-weight: 500;
}
@media (max-width: 768px) {
    .servicios-banner-text-header{
        font-size: 30px;
    }
}
.servicios-banner-text-subheader{
    color: white;
    font-size: 14px;
}
@media (max-width: 768px) {
    .servicios-banner-text-subheader{
        font-size: 12px;
    }
}
.servicios-container{
    padding-top: 80px;
    padding-bottom: 80px;
}
@media (max-width: 767px) {
    .servicios-container{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.servicios-card{
    height: 100%;
}
.servicios-card-title{
    font-family: "Playfair Display";
    font-size: 18px;
    font-weight: 500;
    color: var(--gold-color);
}
.servicios-card-text{
    font-size: 14px;
}
.servicios-card-link{
    color: var(--gold-color);
    text-decoration: underline;
    cursor: pointer;
}
.servicios-img{
    width: 100%;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
@media (max-width: 1023px) {
    .servicios-img{
        height: 100px;
    }
}
.servicios-img-1{
    background-image: url('../public/img/servicios/operaciones-de-garantia-1.png');
}
.servicios-img-2{
    background-image: url('../public/img/servicios/sociedades-y-asocioanes.png');
}
.servicios-img-3{
    background-image: url('../public/img/servicios/inmuebles.png');
}
.servicios-img-4{
    background-image: url('../public/img/servicios/sucesorios.png');
}
.detalle-servicio-header{
    font-family: "Playfair Display";
    font-size: 20px;
    font-weight: 500;
    color: var(--gold-color);
    margin-bottom: 3px;
}
.servicios-back-btn{
    color: var(--blue-highligth);
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 20px;
}
/* Servicios */
/* Fundamentos */
.fundamentos-banner{
    background-image: url('../public/img/fundamentos-banner.jpg');
    background-size: cover;
    width: 100%;
    height: 400px;
}
@media (max-width: 1024px) {
    .fundamentos-banner{
        height: 300px;
    }
}
@media (max-width: 768px) {
    .fundamentos-banner{
        height: 250px;
    }
}
@media (max-width: 767px) {
    .fundamentos-banner{
        height: 200px;
    }
}
.fundamentos-banner-text-header{
    color: white;
    font-family: "Playfair Display";
    font-size: 40px;
    font-weight: 500;
}
@media (max-width: 768px) {
    .fundamentos-banner-text-header{
        font-size: 30px;
    }
}
.fundamentos-banner-text-subheader{
    color: white;
    font-size: 14px;
}
@media (max-width: 768px) {
    .fundamentos-banner-text-subheader{
        font-size: 12px;
    }
}
.fundamentos-container{
    padding-top: 80px;
    padding-bottom: 80px;
}
@media (max-width: 767px) {
    .fundamentos-container{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.fundamentos-header{
    font-family: "Playfair Display";
    font-size: 20px;
    font-weight: 500;
    color: var(--gold-color);
    margin-bottom: 3px;
}
/* Fundamentos */
/* Notario */
.notario-banner{
    background-image: url('../public/img/notario-banner.jpg');
    background-size: cover;
    width: 100%;
    height: 400px;
}
@media (max-width: 1024px) {
    .notario-banner{
        height: 300px;
    }
}
@media (max-width: 768px) {
    .notario-banner{
        height: 250px;
    }
}
@media (max-width: 767px) {
    .notario-banner{
        height: 200px;
    }
}
.notario-banner-text-header{
    color: white;
    font-family: "Playfair Display";
    font-size: 40px;
    font-weight: 500;
}
@media (max-width: 768px) {
    .notario-banner-text-header{
        font-size: 30px;
    }
}
.notario-banner-text-subheader{
    color: white;
    font-size: 14px;
}
@media (max-width: 768px) {
    .notario-banner-text-subheader{
        font-size: 12px;
    }
}
.notario-container{
    padding-top: 80px;
    padding-bottom: 80px;
}
@media (max-width: 767px) {
    .notario-container{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.notario-header{
    font-family: "Playfair Display";
    font-size: 20px;
    font-weight: 500;
    color: var(--gold-color);
    margin-bottom: 3px;
}
.notario-highligth{
    color: var(--gold-color);
    text-decoration: none;
}
.notario-highligth:hover{
    color: var(--gold-color);
}
.notario-highligth:active{
    color: var(--gold-color);
}
.notario-img{
    background-image: url('../public/img/notario.jpg');
    background-size: cover;
    width: 100%;
    height: 400px;
}
/* Notario */
/* Equipo */
.equipo-banner{
    background-image: url('../public/img/equipo-banner.jpg');
    background-size: cover;
    width: 100%;
    height: 400px;
}
@media (max-width: 1024px) {
    .equipo-banner{
        height: 300px;
    }
}
@media (max-width: 768px) {
    .equipo-banner{
        height: 250px;
    }
}
@media (max-width: 767px) {
    .equipo-banner{
        height: 200px;
    }
}
.equipo-banner-text-header{
    color: white;
    font-family: "Playfair Display";
    font-size: 40px;
    font-weight: 500;
}
@media (max-width: 768px) {
    .equipo-banner-text-header{
        font-size: 30px;
    }
}
.equipo-banner-text-subheader{
    color: white;
    font-size: 14px;
}
@media (max-width: 768px) {
    .equipo-banner-text-subheader{
        font-size: 12px;
    }
}
.equipo-container{
    padding-top: 80px;
    padding-bottom: 80px;
}
@media (max-width: 767px) {
    .equipo-container{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.equipo-card{
    margin-bottom: 25px;
}
.equipo-img{
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    width: 100%;
    height: 250px;
}
.equipo-header{
    font-family: "Playfair Display";
    font-size: 20px;
    font-weight: 500;
    color: var(--gold-color);
    margin-bottom: 3px;
}
.equipo-puesto{
    font-size: 12px;
    color: var(--desc-text);
}
/* Equipo */
/* Cancelar solicitud */
.cancelar-solicitud-header{
    font-family: "Playfair Display";
    font-size: 28px;
    font-weight: 600;
    color: var(--gold-color);
}
.cancelar-solicitud-subheader{
    color: black;
}
/* Cancelar solicitud */
/* AntD */
.ant-input-filled {
    background: #EEEEEE;
    border-color: none;
    border-radius: 2px !important;
    color: black !important;
}
.ant-otp .ant-otp-input:focus{
    background: #EEEEEE;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}
/* AntD */